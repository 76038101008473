import { StaticQuery, graphql } from "gatsby";
import React from "react";
import emVideoBackup from "../mp4/backups/e2-comp.mp4";
import DevicePageTemplate from "components/common/templates/DevicePageTemplate";

export default function EnvironmentSensorsPage() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "environmental monitoring",
        title:
          "Environmental Sensors to Maximize Safety with Air Quality Monitoring and Audio Detection",
        description:
          "Environmental safety at your fingertips with air quality monitoring, advanced vape detection, temperature and humidity insights, and audio analytics. Respond quickly to environmental changes by integrating environmental sensors with camera footage and real-time alerts.",
        image: data.headerImage,
        button1: { text: "Start Trial", path: "/free-trial" },
        button2: { text: "View Pricing", path: "/pricing/sensors" },
      },
      cardSection: {
        title: "Explore Environmental Sensors",
        cards: [
          {
            image: data.device1,
            name: "E50",
            description: "Monitor air quality and detect audio.",
            link: "/sensors/environmental-monitoring/e50/",
            flex: 2,
            altText:
              "Rhombus E50 environment sensor mounted on ceiling, includes temperature and humidity sensor, and air quality in a data center. The data from E50 sensor is crucial for optimizing cooling systems, preventing hot spots, and maintaining the overall health and efficiency of the data center. Remote monitoring and early detection of environmental hazards enable data center managers to take proactive measures, preventing downtime and reducing energy consumption. ",
          },
          {
            image: data.device2,
            name: "E15",
            description: "Monitor temperature and humidity.",
            link: "/sensors/environmental-monitoring/e15/",
            flex: 2,
          },
        ],
      },
      benefitsSection: {
        title: "Get Real-Time Environmental Insights Into Your Spaces",
        items: [
          {
            image: data.benefit1,
            altText:
              "Rhombus E15 environment sensor mounted in cold storage, environmental monitoring temperature (like temperature sensors) and humidity for optimal climate control.",
            title: "Protect Climate-Controlled Assets",
            description:
              "Monitor temperature and humidity in real time. Prevent spoilage, boost quality control, and minimize equipment failure with leak detection and proactive alerts.",
          },
          {
            image: data.benefit2,
            altText:
              "A rhombus-shaped environmental sensor installed inside a restroom, designed for monitoring air quality and detecting vape. This connected device features humidity sensors and monitors temperature, providing accurate data on ambient temperature and relative humidity.",
            title: "Detect Vape & Smoke",
            description:
              "Elevate safety and compliance by reliably detecting vape and smoke particles. Pair real-time sensor data with video evidence to enhance accountability and take swift action.",
          },
          {
            image: data.benefit3,
            altText:
              "A connected security system in action. An environmental monitor E50 detects the sound of breaking glass as an intruder enters. The device measures and analyzes the acoustic signature, triggering an alert notification to security personnel. The smart sensor is mounted on a rack alongside other components, showcasing its integration into a comprehensive environmental monitoring setup that also measures factors like airflow, temperature, humidity, vape, particulate matter and more.",
            title: "Expand Security with Audio Insights ",
            description:
              "Improve situational awareness without compromising privacy with E50 audio detection for glass break and loud noises. Protect areas where cameras cannot be installed.",
          },
        ],
      },
      specSection: {
        title: "Capture More than Video",
        specs: [
          { icon: data.icon1, title: "Temperature", subTitle: "Monitoring" },
          { icon: data.icon2, title: "Humidity", subTitle: "Measuring" },
          {
            icon: data.icon3,
            title: "Indoor Air Quality",
            subTitle: "Measuring",
          },
          { icon: data.icon4, title: "Smoke/Vape", subTitle: "Detection" },
          { icon: data.icon5, title: "Loud Noise", subTitle: "Detection" },
          {
            icon: data.icon6,
            title: "Particulate Matter",
            subTitle: "Detection",
          },
        ],
      },
    };

    const faq = {
      title: "Environmental Sensors FAQs",
      image: data.faq,
      alt:
        "An environmental sensor is installed in a college or a school restroom, actively detecting vape emissions. The device is designed to monitor air quality and identify harmful chemicals associated with vaping, contributing to a safer environment for students. The visible presence of the sensor serves as a deterrent against vaping in this private space.",
      QA: [
        {
          question: "What is Environmental Monitoring?",
          answer:
            "Environmental monitoring is the process of tracking and analyzing various environmental parameters. This involves the use of advanced environmental sensors such as the E50 to measure critical factors such as temperature, humidity, air quality and harmful substances. By continuously monitoring these parameters, organizations can ensure public safety, protect property, and preserve resources. Environmental monitoring is essential in a wide range of applications, including industrial environments, where maintaining optimal conditions is crucial for operational efficiency and sustainability.",
        },
        {
          question:
            "What data can be collected from Rhombus environmental sensors, and how is it used?",
          answer:
            "Rhombus environmental sensors collect a variety of data, including ambient temperature, relative humidity, and air quality metrics. Some of the air quality metrics include carbon monoxide, carbon dioxide, atmospheric air pressure, and smoke particles. This data is invaluable for facility managers and safety professionals, as it can be analyzed to identify trends, detect anomalies, and optimize working conditions.",
        },
        {
          question:
            "What makes Rhombus sensors suitable for a wide range of environments?",
          answer:
            "Rhombus environmental sensors are designed to be versatile and robust, making them suitable for a wide range of environments. These environmental sensors can accurately monitor temperature and humidity in various settings, from office spaces to industrial facilities. The sensors are capable of operating in diverse conditions, providing reliable data whether in a controlled office environment or a more challenging industrial setting.",
        },
        {
          question: "How is Environmental Monitoring used in Data Centers?",
          answer:
            "Environmental monitoring in data centers is vital for ensuring the optimal performance and reliability of IT equipment. A data center is particularly susceptible to environmental hazards such as high temperatures, humidity, and air pollution, which can lead to equipment failure and costly downtime. To mitigate these risks, one can employ a variety of sensors, including temperature sensors, humidity sensors, air quality sensors, and pressure sensors, to track environmental conditions in real-time. The E50 environmental sensors can track all of these parameters and is an all-in-one solution. ",
        },
      ],
    };

    return (
      <>
        <DevicePageTemplate
          data={pageData}
          image={data.specImage}
          videoBackup={emVideoBackup}
          cameras={false}
          faq={faq}
        />
      </>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/e50-e15-podium-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device1: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e50-card-wide-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e15-card-wide-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/e15-temperature-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/e50-vape-detection-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/e50-glass-break-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      specImage: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/e50-wall-mount-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/temperature.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/humidity.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/air.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/cigarette.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon5: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/impact.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon6: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/particles.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImageMobile: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      faq: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/e50-faq-rounded.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
