import GlobalLayout from "components/page/GlobalLayout";
import EnvironmentSensorsPage from "components/sensors/environment-monitoring/updated/EnvironmentSensorsPage";
import React from "react";
import Helmet from "react-helmet";

export default function EnvironmentalMonitoring() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Rhombus Environmental Sensors - Vape, Smoke, Air Quality, Loud Noise,
          Humidity & More
        </title>
        <meta
          name="description"
          content="Rhombus offers advanced environmental monitoring sensors to track temperature, humidity, air quality, smokinging, vaping, loud noise, and more. Gain real-time insights to create healthier spaces and protect climate-controlled assets. Learn more about our environmental monitoring solutions."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-environmental-sensors-min.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-environmental-sensors-min.png"
        />
      </Helmet>
      <EnvironmentSensorsPage />
    </GlobalLayout>
  );
}
